import { FC, useEffect } from "react";
import styled from "styled-components";
import StatCard from "components/common/StatCard";
import useScrollToTop from "components/common/useScrollToTop";
import { ColSpanEnum, stats365, stats776Founders } from "enum/stats";
import { Header } from "components";
import useStats from "components/useStats";
import Shape from "components/common/Shape";
import { generateColSpanClasses } from "utils/utils";

const StatsSection = styled.section`
  .content-wrapper {
    position: relative;
    z-index: 1;
  }

  .main-content {
    max-width: 1740px;
    margin: 0 auto;
    padding: 175px 70px 175px 70px;

    h2 {
      width: 1100px;
      max-width: 100%;
      font-size: 40px;
      font-weight: 400;
      line-height: 55px;
      margin-bottom: 100px;
      color: ${({ theme }) => theme.colors.textPrimary};
    }
    .stats-section {
      overflow: hidden;
    }

    .stats-wrapper {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(12, 1fr);
      gap: 0px;
      /* add width to cover the outside borders */
      margin-left: -2px;
      margin-top: -2px;
    }
  }

  .stat-card {
    grid-column: span 2;

    ${generateColSpanClasses(ColSpanEnum.XL)}

    @media ${({ theme }) => theme.mediaQueries.mediumDesktop} and (min-width: 1024px) {
      ${generateColSpanClasses(ColSpanEnum.MEDIUM_DESKTOP)}
    }

    @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
      ${generateColSpanClasses(ColSpanEnum.BELLOW_TABLET_LANDSCAPE)}
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    .main-content {
      padding: 115px 25px 70px 25px;
      h2 {
        width: 730px;
        font-size: 30px;
        font-weight: 400;
        line-height: 44px;
        margin-bottom: 60px;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    .main-content {
      h2 {
        font-size: 19px;
        font-weight: 400;
        line-height: 31px;
        margin-bottom: 40px;
      }
    }
  }
`;

const Stats: FC = () => {
  useScrollToTop();
  const { stats, loading, loadStats } = useStats();

  useEffect(() => {
    loadStats(true);
  }, [loadStats]);

  if (loading) return null;

  const items = [...stats365(stats, true), ...stats776Founders(stats, true)];

  return (
    <StatsSection>
      <Shape />

      <div className="content-wrapper">
        <Header showShape />

        <div className="main-content">
          <h2>
            You found the easter egg! Here's a detailed breakdown of what Alexis Ohanian has done to support 776
            founders in the last 365 days.
          </h2>
          <div className="stats-section">
            <div className="stats-wrapper">
              {items.map((stat, index) => (
                <StatCard className="stat-card" key={index} {...{ ...stat, colSpan: stat.colSpanPopup }} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </StatsSection>
  );
};

export default Stats;
